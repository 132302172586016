<template>
    <div>
        <v-form @submit.prevent="submit">
            <v-text-field v-model="dataObject.name"
                          label="Name"
                          clearable/>

            <v-file-input v-model="dataObject.logo"
                          accept="image/*"
                          label="Logo"
            />
            <v-row no-gutters>
                <v-spacer/>
                <v-btn @click="closeModal" color="primary" text class="mr-3">Cancel</v-btn>
                <v-btn type="submit" color="primary" depressed>Save</v-btn>
            </v-row>
        </v-form>
    </div>
</template>

<script>
    import {createFormData} from "@/services/validationRules";

    export default {
        props: {
            dataObject: Object
        },
        name: "Form",
        data: () => ({

        }),
        methods: {
            submit() {
                this.$emit('send-partner', createFormData(this.dataObject))
            },
            closeModal() {
                this.$emit('close-modal');
            }
        },
    }
</script>

<style scoped>

</style>