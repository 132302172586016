<template>
    <div>
        <div class="d-flex justify-space-between align-center">
            <div class="font-weight-medium">
                Partners
            </div>
            <v-btn
                    color="primary"
                    elevation="2"
                    @click="openForm(null, createPartnersObject)"
            >Add partner</v-btn>
        </div>

        <v-card class="mx-auto my-4">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>N</th>
                            <th>Name</th>
                            <th>Img</th>
                            <th class="text-right">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(partner, index) in getPartners">
                        <td>{{ index + 1 }}</td>
                        <td>{{ partner.name }}</td>
                        <td class="partner__img"><img :src="baseURL + partner.logo" alt=""></td>
                        <td class="text-right">
                            <template>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="openForm(partner, createPartnersObject)"
                                               v-on="on"
                                               v-bind="attrs"
                                               icon color="info">
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="deleteItem(partner)"
                                               v-on="on"
                                               v-bind="attrs"
                                               icon color="red">
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Delete</span>
                                </v-tooltip>
                            </template>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>

        </v-card>

        <Modal v-model="modal"
               width="1140px"
               title="Partners form">
            <Form v-if="modal"
                  :data-object="dataObject"
                  @close-modal="modal = false"
                  @send-partner="sendPartner"
            />
        </Modal>
    </div>
</template>

<script>
    import Form from "./Form";
    import formModal from "@/mixins/formModal";
    import deleteModal from "@/mixins/deleteModal";
    import { baseURL } from "@/services/api";
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "List",
        data: () => ({
            baseURL,
            lang: 'uz',
            languages: ['uz', 'ru', 'en'],
        }),
        components: { Form },
        computed: {
            ...mapGetters({
                getPartners: 'getPartners',
            })
        },
        mixins: [formModal, deleteModal],
        methods: {
            ...mapActions({
                fetchPartners: 'fetchPartners',
                createPartners: 'createPartners',
                updatePartners: 'updatePartners',
                deletePartners: 'deletePartners',
            }),

            createPartnersObject (partner = null) {
                const object = {
                    name: partner ? partner.name : '',
                    logo: null,
                };
                if (partner) object.id = partner.id;
                return object;
            },

            async sendPartner(data) {
                if (this.dataObject.id) {
                    await this.updatePartners(data);
                } else {
                    await this.createPartners(data);
                }

                await this.fetchPartners();
                this.modal = false;
            },

            async deleteItem(partner) {
                await this.deletePartners(partner.id);
                await this.fetchPartners();
            }
        },
        async mounted() {
            await this.fetchPartners();
        }
    }
</script>

<style scoped lang="scss">
    .partner__img {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        overflow: hidden;

        & img {
            height: 45px;
        }
    }
</style>
